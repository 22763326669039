.Grid {
    .Droppable {

        &__Container {
            position: absolute;
            left: 50%;
        }


        &__Row {
            display: flex;
            position: relative;
            margin-bottom: 0.01px;
        }

        &__Cell {
            position: relative;
            display: inline-block;
            height: 100%;
            margin-right: 0.01px;
            flex: 1 0 auto;

            // &.used, &.unusable { background: rgba(255,0,0,0.1); }
            &.hovered { background: rgba(0,255,0,0.5); }

            &.used.hovered,
            &.hovered.nospace { background: rgba(255,0,0,0.3); }

            &.unusable.hovered { background: rgba(246,111,0,0.3); }

            .Draggable {
                position: absolute;
                top: 0;
                bottom: 0;
            }

            img {
                position: absolute;
                z-index: 1;
            }
        }

    }

    .Draggable {
        cursor: pointer;
    }

}