.sweet-alert {
    @media (min-width: $screen-md) {
        width: 50vw !important;
        margin-left: -25vw !important;
    }

    & > p {
        color: #444 !important;
        padding: 15px !important;
        max-height: 50vh;
        overflow-y: auto;
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .ItemImage {
        text-align: center;

        img {
            max-width: 100%;
            max-height: 100px;
        }
    }

    button.confirm {
        background-color: $orange !important;
        &:hover {
            opacity: 0.9;
        }
    }
}
