.Print {
    // also check _order.scss

    .cabinet, .washstand {
        background-size: contain;
        background-position: center top;
        background-repeat: no-repeat;
        height: 300px;
    }


    .power-planning {
        // display: flex;
        // justify-content: space-around;
        margin-top: 50px;

        .cabinet-plan {
            position: relative;
            text-align: center;

            img {
                max-width: 100%;
                max-height: 500px;
                opacity: 0.3;
            }

            .InstallationLayer {
                position: absolute;
            }

            &.cabinet {
                &-slim {
                    .InstallationLayer {
                        margin-top: 11.5%;
                        margin-bottom: 12%;
                    }

                    &-XS .InstallationLayer {
                        left: 38%;
                        right: 38%;
                    }

                    &-XL .InstallationLayer {
                        left: 26%;
                        right: 26%;
                    }

                    &-XXL .InstallationLayer {
                        left: 14%;
                        right: 14%;
                    }
                }

                &-euro {
                    .InstallationLayer {
                        margin-top: 10%;
                        margin-bottom: 12%;
                    }
                    
                    &-XS .InstallationLayer {
                        left: 38%;
                        right: 38%;
                    }

                    &-XL .InstallationLayer {
                        left: 25%;
                        right: 25%;
                    }
                }
            }
        }
    }
}