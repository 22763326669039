.Preset {
    display: inline-block;
    margin: 0 30px 30px;
    text-align: center;

    &__Title {
        display: block;
        padding: 5px 0;
    }

    img {
        height: 100px;
    }
}