.Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 86px;
    z-index: 999;
    background: $grey;

    &-small {
        height: auto;
        padding: 10px 0;
    }

    &__Title {
        margin-top: 10px;          
        font-size: 20px;
        line-height: 26px;
        color: $orange;
        font-weight: 600;
        text-transform: uppercase; 
    }

    @media (min-width: $screen-md) {
        &__Title {
            font-size: 24px;
        }
    }

    &__Toolbar {
        margin-bottom: 10px; 

        .btn {
            text-transform: uppercase;
        }
    }

    @media(min-width: $screen-md) {
        &__Toolbar {
            text-align: center;
        }
    }

    &__Logo {
        position: absolute;
        left: 10px;
        top: 10px;

        img { max-height: 30px; }
    }

    @media(min-width: $screen-md) {
        &__Logo {
            img { max-height: 40px; }
        }
    }

    @media(min-width: $screen-md) {
        &__Logo {
            img { max-height: 50px; }
        }
    }

    &__Cart {
        position: absolute;
        right: 10px;
        top: 10px;
    }    
}