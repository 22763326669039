.login {

    a {
        color: $orange !important;
    }

    &-help {
        margin-top: 20px;
        font-size: 10px;

        // a {
        //     margin-left: -3px;
        // }
    }

    &-register {
        margin-top: 24px;
    }

    &-highlight {
        color: $orange !important;
    }
}