.Navbar {
    position: fixed;
    top: 86px;
    left: 0;

    a {
        display: block;
        padding: 10px;
        text-align: left;

        & > span {
            position: absolute;
            left: 100%;
            text-transform: uppercase;
            width: 220px;
        }

        & > .fa { transform: translateY(6px); }
    }
}