[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

body {
    padding: 100px 30px 300px 30px;
    background-color: $grey-light;
}

html, body {
    overflow-x: hidden;
}

a {
    color: darken($grey-dark, 25%);
    cursor: pointer !important;

    &:hover, &:active, &:focus {
        text-decoration: none;
        color: $orange;
    }
}

div:not(.sweet-alert) {
    h2 {
        display: inline-block;
        font-size: 20px;
        color: white;
        background: $orange;
        padding: 4px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: $orange;
    }
}

.small {
    font-size: 10px;
    opacity: 0.6;
}

.fixed-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}

input, textarea {
    &.ng-invalid.ng-dirty {
        border-color: rgba(255,0,0,0.5);
    }
}

.fa.highlight {
    color: $orange;
}

.label-as-badge {
    border-radius: 1em;
}

.btn-group-round {
    &.btn-group-xs {
        .btn:first-child {
            border-bottom-left-radius: 11px;
            border-top-left-radius: 11px;
        }

        .btn:last-child {
            border-bottom-right-radius: 11px;
            border-top-right-radius: 11px;
        }
    }
}

*:not(input):not(textarea) {
    -webkit-user-select: none; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

.no-scroll {
    overflow: hidden;
}