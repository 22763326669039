// FONTS

@import "fonts/open-sans";

$orange: #F66F00;
$grey: #EEE;
$grey-dark: #AAA;
$grey-light: #f9f9f9;
$grey-darkened: #6a6a6a;

$font-family-base: 'Open Sans';
$brand-primary: $orange;
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$fa-font-path: '../../fonts';
@import "node_modules/font-awesome/scss/font-awesome";

@import "mixins";
@import "sweetalert";
@import "global";
@import "header";
@import "footer";
@import "start";
@import "grid";
@import "cabinet";
@import "washstand";
@import "item-picker";
@import "toolbar";
@import "navbar";
@import "cart";
@import "order";
@import "print";
@import "preset";
@import "installation-layer";
@import "pace";
@import "hints";
@import "overlay";
@import "login";
@import "captcha";