.Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 34px;
    z-index: 999;
    font-size: 12px;
    line-height: 1;

    background: $grey-darkened;
    color: white;
    padding-top: 10px;
    box-shadow: 0 -3px 4px 1px rgba(0, 0, 0, 0.3);

    a {
        color: white;
        display: inline-block;
        cursor: pointer;
    }
}