.Washstand {
    width: 900px; 
    height: 520px;
    margin: auto;
    margin-bottom: -90px;
    padding-bottom: 300px;
    
    @media (min-width: $screen-lg) {
        width: 1300px; 
        height: 800px;
        margin-bottom: -160px;
    }

    &-print {
        padding-bottom: 0;
    }

    .Droppable {

        &__Container {
            margin-top: 226px;
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100% 100%;
            height: 255px;

            &--XXL { 
                padding: 0 70px;
                background-image: url('/img/bg/washstand-xxl.png');
                width: calc(666px + 2 * 70px);
                margin-left: calc(-333px - 70px);
            }
            &--XL { 
                padding: 0 44px;
                background-image: url('/img/bg/washstand-xl.png');
                width: calc(444px + 2 * 44px);
                margin-left: calc(-222px - 44px);
            }
            &--XS { 
                padding: 0 24px;
                background-image: url('/img/bg/washstand-xs.png');
                width: calc(222px + 2 * 24px);
                margin-left: calc(-111px - 24px);
            }

            @media (min-width: $screen-lg) {
                margin-top: 333px;
                height: 382px;

                &--XXL { 
                    padding: 0 102px;
                    width: calc(1000px + 2 * 102px);
                    margin-left: calc(-500px - 102px);
                }
                &--XL { 
                    padding: 0 70px;
                    width: calc(666px + 2 * 70px);
                    margin-left: calc(-333px - 70px);
                }
                &--XS { 
                    padding: 0 35px;
                    width: calc(333px + 2 * 35px);
                    margin-left: calc(-167px - 35px);
                }
            }
        }


        &__Cell {
            img {
                position: absolute;
                z-index: 3;
                bottom: 10px; 

                @media (min-width: $screen-lg) {
                    bottom: 15px;
                }
            }
        }

        &__Row {
            height: 70px;
            transform: translate3d(0,-339px,0);

            &:first-child { 
                height: 339px;

                img { bottom: 0 !important; }
            }
            
            @media (min-width: $screen-lg) { 
                height: 105px; 
                transform: translate3d(0,-500px,0);

                &:first-child { height: 500px; }

            }
        }
    }
    

}