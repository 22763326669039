.Start {

    .colorized {
        background-color: #2e6da4;
        height: 200px;
    }

    .Main {
        &__Container {
            position: absolute;
            left: 0;
            right: 0;
            top: 120px;
            bottom: 0;
        }
    }

    .Hint {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #777777;
        
        @media (max-width: $screen-lg) { 
            justify-content: flex-start;
            text-align: center;
        }

        .small { font-size: 20px; }
        .big { font-size: 31px; }
    }

    .Droppables__Container {
        position: relative;
        min-height: 200px;
        max-height: 400px;
        margin: 0 20px;

        @media (min-width: $screen-lg) { height: 400px; }

        display: flex;
        justify-content: space-between;
        align-items: center;

        &.drag-enter {
            background: $orange;
            opacity: 0.7;

            .Droppable {
                &_left, &_right, &_center {
                    visibility: visible;
                }

                span {
                    color: white;
                }
            }

            .Hint { color: white; }
        }

        .Droppable {
            z-index: 1;
            width: 400px;
            text-align: center;
            cursor: pointer;
            color: white;

            &.drag-enter {
                background-color: rgba(155, 155, 155, 0.3);
            }

            .delete {
                opacity: 0.3;
            }

            .title {
                color: $orange;
                margin: 20px 0;
            }

            .cabinet, .washstand {
                background-size: contain;
                background-position: center top;
                background-repeat: no-repeat;
                height: 160px;
                
                @media (min-width: $screen-lg) { width: 400px; }
            }

            &.flex_order {
                &-0 {
                    order: -5;
                }

                &-1 {
                    order: 1;
                }

                &-2 {
                    order: +5;
                }
            }

            &_left, &_right, &_center {
                display: flex;
                align-content: center;
                align-self: stretch;
                height: 400px;
                z-index: 1;
                opacity: 0.3;

                &.hidden { visibility: hidden; }


                &.drag-enter {
                    background-color: rgba(255, 255, 255, 0.2);
                    opacity: 1;
                }

                i {
                    margin: auto;
                }
            }

            &_left {
                width: 50%;
                order: -10;
            }
            &_right {
                width: 50%;
                order: 10;
            }

            &_center {
                width: 180px;
                order: 0;
            }


        }
    }

}