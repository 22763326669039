.Toolbar {
    position: fixed;
    top: 86px;
    right: 0;

    .power_options {
        text-align: right;
    }

    a {
        display: block;
        padding: 10px;
        text-align: right;

        .title {
            width: 118px;
            display: inline-block;
        }

        .icon {
            width: 35px;
            display: inline-block;
            text-align: center;
            vertical-align: top;

            .fa { transform: translateY(6px); }

            .fa-stack-1x {
                img {
                    max-width: 100%;
                    height: 1em;
                    margin-top: -0.2em;
                }
            }
        }

        &.btn {
            padding-left: 15px;
            margin: 5px 10px;
            display: inline-block;

            i.fa {
                margin-left: 10px;
            }
        }

        &.opaque {
            opacity: 0.3;
        }
    }
}