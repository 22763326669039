.Cart {

    &__Infobox {
        display: block;
        height: 65px;
        padding: 10px;
        padding-left: 10px;
        background: linear-gradient(to top, $orange 0%, lighten($orange, 5%) 100%);
        color: black !important;

        &:hover { opacity: 0.8; }

        .fa { 
            color: white; 
        }

        .fa-chevron-right {
            margin-top: 6px;
            margin-left: 10px;
        }

        .badge {
            position: absolute;
            margin-left: 16px;
            margin-top: -4px;

            & + .fa {
                border-right: 1px solid white;
                padding-right: 10px;
                margin-right: 10px;
            }
        }

        .Price {
            font-size: 20px;
            font-weight: 600;
        }

        .Price-Note {}
    }
}