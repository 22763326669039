.Item-Picker {
    background: $grey;
    margin-left: -15px;
    margin-bottom: 34px;
    transition: transform 0.33s;

    &--hidden {
        transform: translateY(100%);
    }

    h2 { margin: 0; }

    &__Hide {
        position: absolute;
        right: 10px;
        background: $grey;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transform: translateY(-100%);
    }

    &__CabName {
        position: absolute;
        padding: 10px;
        transform: translateY(-100%);
        left: 10px;
        color: $orange;
    }

    &__Nav {
        width: 240px;
        position: relative;
        margin: 0 45px 0 0;

        li {
            margin: 1px 0;
            background: $orange;
            background: linear-gradient(to top, $orange 0%, lighten($orange, 5%) 100%);
            font-size: 16px;
            font-weight: 600;
            height: 38px;

            &.active, &:hover {
                background: $grey-dark;
            }

            &.active::before {
                content: '';
                display: block;
                position: absolute;
                margin-right: -19px;
                right: 0;
                width: 0;
                height: inherit;
                border-style: solid;
                border-width: 19px 0 19px 19px;
                border-color: transparent transparent transparent $grey-dark;
            }

            a {
                display: block;
                padding: 9px 20px 12px 30px;
                color: white;
                text-transform: uppercase;
            }
        }
    }

    &__SubCategories {
        height: 30px;
        width: calc(100% - 310px);
        margin-top: 10px;
        overflow: hidden;

        a {
            margin-right: 5px;
            &.active, &:active {
                background-color: $orange;
                color: white;
            }
        }

        .noScrollbar {
            height: 50px;
            padding-bottom: 20px;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }

    &__Arrow {
        position: absolute;
        height: 30px;
        width: 20px;
        padding-top: 100px;
        text-align: center;

        &:first-of-type { margin-left: -10px; }
        &:last-of-type { 
            right: 10px;
        }
    }

    &__Items {
        height: 170px;
        width: calc(100% - 312px);
        margin: 10px 10px 0 0;

        li {
            position: relative;
            height: 210px;
            min-width: 120px;
            margin: 0 8px 40px 8px;
            padding: 0;
            overflow: hidden;

            .amount {
                margin-bottom: 10px;
            }

            .amount-btn {
                padding: 4px 20px;
            }

            img {
                max-width: 100%;
                height: 80px;
                margin-bottom: 10px;
            }

            &.incompatible { 
                opacity: 0.5;
                
                a {
                    cursor: not-allowed !important;
                }
            }

            .Info {
                position: absolute;
                left: 0;
                right: 0;
            }

            .Title, .Description, .Price {
                display: block;
                padding: 0 2px;
                font-size: 12px;

                .fa {
                    margin: 2px 2px 0 0;
                }
            }

            .Title, .Price {
                font-weight: 600;
            }

            .Description {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .Price-Note {}
        }
    }
}

.ItemDetails, .PresetDetails {
    text-align: left !important;
    &, p { color: #444 !important; }

    .ItemImage { margin-top: 20px; }
}