.Hints {
    position: fixed;
    @include fullsize;
    background: rgba(255,255,255,0.5);
    z-index: 9999;

    img {
        position: absolute;

        &.choose_category {
            bottom: 275px;
            left: 40px;
        }
        &.drag_items {
            bottom: 275px;
            left: 50%;
            margin-left: -80px;
        }
        &.autoarrange {
            top: 170px;
            right: 150px;
        }
        &.plan_installations {
            top: 230px;
            right: 160px;
        }
        &.order {
            top: 80px;
            right: 160px;
        }
    }
}