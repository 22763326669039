.Cabinet {
    padding-top: 140px;
    height: 400px;
    width: 900px; 
    margin: auto;

    @media (min-width: $screen-lg) {
        padding-top: 150px;
        height: 600px;
        width: 1300px; 
    }

    .label { font-size: 90% }

    .Droppable {

        &__Container {
            &.drag-enter { 
                .bigDroppableHighlight {
                    @include fullsize;
                    z-index: 3;
                    background: rgba(0,255,0,0.5) !important; 
                }
            }

            &--XS { 
                width: 222px; 
                margin-left: -111px;

                .Cabinet__BodyTitle {
                    margin-top: 0;
                    font-size: 20px;
                }

                @media (min-width: $screen-lg) {
                    width: 333px; 
                    margin-left: -166px;
                }
                
                .Body__front {
                    &-slim {
                        left: -93px;
                        right: -93px;
                        top: -303px;
                        
                        @media (min-width: $screen-lg) {
                            left: -139px;
                            right: -139px;
                            top: -455px;                        
                        }
                    }

                    &-euro {
                        left: -118px;
                        right: -118px;
                        top: -344px;

                        @media (min-width: $screen-lg) {
                            left: -180px;
                            right: -180px;
                            top: -521px;
                        }
                    }
                }
            }

            &--XL { 
                width: 444px; 
                margin-left: -222px;

                @media (min-width: $screen-lg) {
                    width: 666px; 
                    margin-left: -333px;
                }
                
                .Body__front {
                    &-slim {
                        left: -85px;
                        right: -85px;
                        top: -272px;
                        
                        @media (min-width: $screen-lg) {
                            left: -128px;
                            right: -128px;
                            top: -408px;                        
                        }
                    }

                    &-euro {
                        left: -107px;
                        right: -107px;
                        top: -297px;

                        @media (min-width: $screen-lg) {
                            left: -164px;
                            right: -164px;
                            top: -448px;
                        }
                    }
                }
            }

            &--XXL { 
                width: 666px; 
                margin-left: -333px;

                @media (min-width: $screen-lg) {
                    width: 1000px; 
                    margin-left: -500px;
                }
                
                .Body__front {

                    .label {
                        cursor: pointer;
                        top: 340px !important;
                        
                        @media (min-width: $screen-lg) {
                            top: 520px !important;
                            left: 90px !important;
                        }
                    }

                    &-slim {
                        left: -123px;
                        right: -123px;
                        top: -396px;
                        
                        @media (min-width: $screen-lg) {
                            left: -185px;
                            right: -185px;
                            top: -600px;                        
                        }
                    }
                }
            }

            .Body {
                transition: opacity 1s;

                &.opaque { opacity: 0.3; }

                &__back {
                    @include fullsize;
                    background-image: url('/img/bg/rear-panel.png');
                    background-size: 100% 100%;
                    z-index: -1;
                }

                &__top {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    background-size: 100% 100%;
                    transform: translate3d(0,-100%,0);

                    &-euro {
                        height: 24px;
                        background-image: url('/img/bg/euro-top.png'); 
                    }

                    &-slim {
                        height: 17px;
                        background-image: url('/img/bg/slim-top.png'); 
                    }

                    @media (min-width: $screen-lg) {
                        &-euro { height: 36px; } // faktor: 2.5
                        &-slim { height: 26px; } // faktor: 2.63
                    }
                }

                &__left {
                    position: absolute;
                    left: 0;
                    background-size: 100% 100%;
                    transform: translate3d(-100%,0,0);

                    &-euro { 
                        top: -24px;
                        width: 40px;
                        height: 226px;
                        background-image: url('/img/bg/euro-left.png'); 
                    }

                    &-slim { 
                        top: -17px;
                        width: 25px;
                        height: 213px;
                        background-image: url('/img/bg/slim-left.png'); 
                    }

                    @media (min-width: $screen-lg) {
                        &-euro { 
                            top: -36px;
                            width: 61px;
                            height: 339px;
                        }

                        &-slim { 
                            top: -26px;
                            width: 38px;
                            height: 319px;
                        }
                    }

                    .label {
                        cursor: pointer;
                        position: absolute;
                        transform: translateX(-70%);

                        &:nth-child(1) { top: 20px; }
                        &:nth-child(2) { top: 20px; left: -20px; }
                        &:nth-child(3) { top: 55px; }
                        &:nth-child(4) { bottom: 5px; }
                    }
                }

                &__right {
                    position: absolute;
                    right: 0;
                    background-size: 100% 100%;
                    transform: translate3d(100%,0,0);

                    &-euro { 
                        top: -24px;
                        width: 40px;
                        height: 226px;
                        background-image: url('/img/bg/euro-right.png'); 
                    }

                    &-slim { 
                        top: -17px;
                        width: 25px;
                        height: 213px;
                        background-image: url('/img/bg/slim-right.png'); 
                    }

                    @media (min-width: $screen-lg) {
                        &-euro { 
                            top: -36px;
                            width: 61px;
                            height: 339px;
                        }

                        &-slim { 
                            top: -26px;
                            width: 38px;
                            height: 319px;
                        }
                    }

                    .label__fitting {
                        cursor: pointer;
                        position: absolute;
                        transform: translateX(-50%);
                        top: 55px; 
                        right: -20px;
                    }
                }

                &__front {
                    position: absolute;
                    pointer-events: none;
                    
                    img { 
                        position: absolute;
                        width: 100%; 
                        z-index: 2;
                    }

                    &-closed {
                        img { 
                            @include fullsize;
                            height: 100%;
                            z-index: 5;
                        }

                        .label {
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            z-index: 6;
                        }

                        &-slim {
                            top: -14px;
                            left: -25px;
                            right: -25px;
                            bottom: -8px;

                            @media (min-width: $screen-lg) {
                                top: -22px;                        
                                left: -38px;
                                right: -38px;
                                bottom: -11px;
                            }
                        }

                        &-euro {
                            top: -21px;
                            left: -40px;
                            right: -40px;
                            bottom: -14px;

                            @media (min-width: $screen-lg) {
                                top: -32px;                        
                                left: -61px;
                                right: -61px;
                                bottom: -33px;
                            }
                        }
                    }
                }

            }
        }


        &__Row {
            height: 94px;
        }

        @media (min-width: $screen-lg) {        
            &__Row {    
                height: 141px;
            }
        }

        &__Cell {
            .label {
                position: absolute;
                transform: translate3d(0,-100%,0);
                padding: 2px 5px;

                &.cartIndex {
                    z-index: 4;
                }
            }

            .item-name {
                position: absolute;
                transform: translate3d(-50%, 0, 0);
                background-color: $orange;
                padding: 2px 5px;
                display: none;
            }
        }

    }

}