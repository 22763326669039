.Order, .Print {

    .Legend {
        &__Icon {
            width: 14px;
            height: 14px;
            border-radius: 7px;
            display: inline-block;

            &-primary {
                background-color: $brand-primary;
            }

            &-grey-dark {
                background-color: $grey-dark;
            }

            &-black {
                background-color: black;
            }
        }
    }

    .PowerItemOverview {
        h3 {
            display: inline;
        }

        .btn-group {
            margin-right: 15px;

            .btn-counter {
                @include button-variant($grey-dark, black, black);
                &.disabled, &[disabled] {
                    @include button-variant(white, $grey-dark, $grey-dark);
                }
            }
        }

    }

    .fa {
        &-grey-darkened {
            color: $grey-darkened;
        }
    }

    .price-footer {
        color: $grey-darkened;
        font-size: 15px;
        margin: -8px 0;
    }
}